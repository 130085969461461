import styled from 'styled-components';

import { flexColumnStart } from '../../../globalsStyles';

interface ContactsTitleProps {
  $highlight: boolean;
}

export const ContactsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 24px;
  width: 25%;
  height: 100%;

  @media (max-width: 1039px) {
    flex-direction: column;
    width: 100%;
    max-width: 990px;
    padding: 0 24px;
    margin: 0;
  }
`;

export const ContactsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: start;
  width: 100%;
  height: 100%;
`;

export const ContactsTitle = styled.h2<ContactsTitleProps>`
  font-size: 36px;
  font-weight: 900;
  letter-spacing: 0.37px;
  color: var(--text-title);
  text-shadow: ${({ $highlight }) => ($highlight ? `0 0 10px var(--highlight-headers)` : 'none')};
  transition: text-shadow 0.6s ease-in-out;
`;

export const ContactsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 244px;
`;

export const ContactsListItem = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--bg-list-cell);
  padding: 12px;
  height: 48px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--control-secondary-hover);
  }

  &:first-child {
    border-radius: 8px 8px 0 0;
  }

  &:last-child {
    border-radius: 0 0 8px 8px;
  }
`;

export const ItemInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 12px;
`;

export const ItemIcon = styled.img`
  width: 24px;
  height: auto;
  user-select: none;
  -webkit-user-select: none;
`;

export const ItemTitle = styled.h2`
  font-size: 16px;
  letter-spacing: -0.5px;
  font-weight: 700;

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    bottom: 0;
    background-color: var(--white);
  }
`;

export const ItemArrow = styled.img`
  width: 24px;
  height: auto;
  user-select: none;
  -webkit-user-select: none;
`;

export const ModalContainer = styled.div`
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: start;
  background-color: rgba(7, 7, 7, 0.9);
  z-index: 1002;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);

  @media (min-width: 720px) {
    align-items: center;
  }
`;

export const ModalContent = styled.div`
  background-color: var(--bg-content);
  padding: 12px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  margin: 80px 8px 0px;
  max-width: 520px;
  position: relative;

  @media (min-width: 720px) {
    margin: 0px 8px 0px;
  }
`;

export const ModalTitle = styled.h1`
  font-weight: 700;
  font-size: 36px;
  margin: 10px 0 32px;
  line-height: 44px;
  letter-spacing: 0.37px;
  color: var(--text-title);
  text-align: left;
`;

export const ModalLabel = styled.h3`
  color: var(--text-body);
  font-size: 16px;
  font-weight: 700;
`;

export const ModalSectionInner = styled.a`
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: var(--bg-list-cell);
  width: 100%;
  border-radius: 4px;
  gap: 12px;
  padding: 12px;
  text-decoration: none;
  min-height: 46px;
`;

export const ModalIcon = styled.img`
  user-select: none;
  -webkit-user-select: none;

  width: 24px;
  height: auto;
`;

export const ModalFooterIcon = styled.img`
  width: 14px;
  height: auto;
  user-select: none;
  -webkit-user-select: none;
`;

export const ModalInnerHeading = styled.h3`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: var(--text-title);
`;

export const ModalInnerSubHeading = styled.h4`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.4px;
  color: var(--text-body);
`;

export const ModalFooterTip = styled.span`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: var(--text-link);
`;

export const ModalFooter = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  text-decoration: none;
  padding: 16px 0;
  margin-top: 9px;
`;

export const ModalInnerContainer = styled.div`
  ${flexColumnStart};

  gap: 4px;
`;

export const ModalSection = styled.div`
  ${flexColumnStart};

  gap: 8px;
`;

export const ModalDivider = styled.hr`
  border: none;
  color: rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
  height: 1px;
  margin: 8px 0;
`;

export const CloseButton = styled.img`
  position: absolute;
  top: 14px;
  right: 14px;
  width: 14px;
  height: auto;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;

  &:hover {
    opacity: 0.7;
  }
`;
