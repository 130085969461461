import React, { useState, useEffect, FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { createSlug } from '../../utils/createSlug';
import { useViewMode } from '../../contexts/ViewModeContext';
import { IShopItem } from '../../types';
import { getShopList } from '../../api/pocketbase';
import { MainContainer, MapWrapper } from './styled';
import { Map } from './Map';
import { Sidebar } from './Sidebar';
import { useGetLocations } from './Sidebar/useGetLocations';
import { MAP_ZOOM, DEFAULT_MAP_CENTER, CITY_COORDINATES } from './Map/mapConfig';

export const Main: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { viewMode } = useViewMode();
  const [mapCenter, setMapCenter] = useState<[number, number]>(DEFAULT_MAP_CENTER);
  const [mapZoom, setMapZoom] = useState<number>(MAP_ZOOM);
  const [allShops, setAllShops] = useState<IShopItem[]>([]);
  const [selectedShop, setSelectedShop] = useState<IShopItem | null>(null);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1040);
  const [loading, setLoading] = useState(true);
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);

  const { data: locations, handleCityChange, currentCity, cities } = useGetLocations({ allShops });

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1040);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    const fetchShops = async () => {
      try {
        const shopList = await getShopList();
        if (isMounted) {
          setAllShops(shopList);
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchShops();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const cityParam = params.get('city');
    const latParam = params.get('lat');
    const lonParam = params.get('lon');
    const zoomParam = params.get('zoom');

    if (latParam && lonParam) {
      const latitude = parseFloat(latParam);
      const longitude = parseFloat(lonParam);
      const zoom = zoomParam ? parseInt(zoomParam, 10) : 16;

      setMapCenter([latitude, longitude]);
      setMapZoom(zoom);

      if (cityParam) {
        const shop = allShops.find(
          (s) =>
            s.latitude !== undefined &&
            s.longitude !== undefined &&
            s.latitude === latitude &&
            s.longitude === longitude &&
            createSlug(s.city) === createSlug(cityParam)
        );

        if (shop) {
          setSelectedShop(shop);
        } else {
          setSelectedShop(null);
        }
      } else {
        setSelectedShop(null);
      }
    } else {
      setSelectedShop(null);
    }
  }, [location.search, allShops]);

  useEffect(() => {
    if (!currentCity) {
      setMapCenter(DEFAULT_MAP_CENTER);
      setMapZoom(MAP_ZOOM);
    } else {
      const citySlug = createSlug(currentCity);
      const cityCoordinates = CITY_COORDINATES[citySlug];

      if (cityCoordinates) {
        setMapCenter(cityCoordinates);
        setMapZoom(MAP_ZOOM);
      }
    }
  }, [currentCity]);

  const handleShopItemClick = (latitude: number, longitude: number, shopInfo: IShopItem) => {
    setIsInteractionBlocked(true);
    setMapCenter([latitude, longitude]);
    setMapZoom(16);
    setSelectedShop(shopInfo);
    setIsInteractionBlocked(false);

    const citySlug = createSlug(shopInfo.city);

    const params = new URLSearchParams();
    params.set('city', citySlug);
    params.set('lat', latitude.toString());
    params.set('lon', longitude.toString());

    navigate(`/?${params.toString()}`, { replace: true });
  };

  const handleMapInteraction = () => {
    setIsInteractionBlocked(true);
  };

  if (loading) {
    return <MainContainer id="bettingShops"></MainContainer>;
  }

  return (
    <MainContainer id="bettingShops">
      <Sidebar
        onShopItemClick={handleShopItemClick}
        onMapInteraction={handleMapInteraction}
        selectedShop={selectedShop}
        allShops={locations}
        handleCityChange={handleCityChange}
        currentCity={currentCity}
        cities={cities}
      />
      {(viewMode === 'MAP' || isLargeScreen) && (
        <MapWrapper>
          <Map
            center={mapCenter}
            zoom={mapZoom}
            allShops={allShops}
            selectedShop={selectedShop}
            isInteractionBlocked={isInteractionBlocked}
            onShopItemClick={handleShopItemClick}
          />
        </MapWrapper>
      )}
    </MainContainer>
  );
};
