import slugify from 'slugify';

export function createSlug(text: string): string {
  const normalizedText = text.trim().replace(/ё/g, 'е');

  return slugify(normalizedText, {
    replacement: '-',
    lower: true,
    strict: true,
    locale: 'ru',
  });
}
