import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';

import LogoImage from '../../assets/logo.png';
import { BurgerButtonIcon, BurgerButtonCloseIcon } from '../../assets/icons';
import { socialLinks as SOCIAL_LINKS, contactPhone as CONTACT_PHONE } from '../../config';
// TODO (D.K.): Temporary switched off
// import { LanguageSwitcher } from './components/LanguageSwitcher';

import {
  HeaderBackground,
  HeaderContent,
  Logo,
  Nav,
  NavLink,
  RightSection,
  SocialIcons,
  SocialLinkImage,
  SocialLink,
  BurgerButton,
  ButtonIcon,
  LogoWrapper,
  PhoneNumberContainer,
  PhoneNumber,
  PhoneNumberIcon,
  LogoLink,
  // TODO (D.K.): Temporary switched off
  // LanguageSwitcherContainer,
} from './styled';

interface HeaderProps {
  onScrollToSection: (id: string) => void;
}

export function Header({ onScrollToSection }: HeaderProps) {
  // TODO (D.K.): Temporary switched off
  // const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleNavLinkClick = (sectionID: string) => {
    onScrollToSection(sectionID);
    setIsOpen(false);
  };

  return (
    <HeaderBackground>
      <HeaderContent>
        <LogoWrapper>
          <BurgerButton onClick={toggleMenu}>
            <ButtonIcon
              src={isOpen ? BurgerButtonCloseIcon : BurgerButtonIcon}
              alt=""
              draggable="false"
              loading="lazy"
            />
          </BurgerButton>
          <LogoLink href="/">
            <Logo src={LogoImage} alt="" draggable="false" loading="lazy" />
          </LogoLink>
        </LogoWrapper>
        {/* // TODO (D.K.): Temporary switched off */}
        {/* <Nav isOpen={isOpen}>
        <NavLink onClick={() => scrollToSection('top')}>{t('nav.bettingShops')}</NavLink>
        <NavLink onClick={() => scrollToSection('news')}>{t('nav.news')}</NavLink>
        <NavLink onClick={() => scrollToSection('contacts')}>{t('nav.contacts')}</NavLink>
      </Nav> */}
        <Nav isOpen={isOpen}>
          <NavLink onClick={() => handleNavLinkClick('top')}>{'Пункты приема ставок'}</NavLink>
          <NavLink onClick={() => handleNavLinkClick('news')}>{'Новости'}</NavLink>
          <NavLink onClick={() => handleNavLinkClick('contacts')}>{'Контакты'}</NavLink>
        </Nav>

        <RightSection isOpen={isOpen}>
          <SocialIcons>
            {SOCIAL_LINKS.map(({ href, icon, alt }, index) => (
              <SocialLink key={index} href={href} target="_blank">
                <SocialLinkImage src={icon} alt={alt} loading="lazy" />
              </SocialLink>
            ))}
          </SocialIcons>
          {/* 
        // TODO (D.K.): Temporary switched off
        <LanguageSwitcherContainer>
          <LanguageSwitcher />
        </LanguageSwitcherContainer>
        */}
          <PhoneNumberContainer href={CONTACT_PHONE.href}>
            <PhoneNumberIcon src={CONTACT_PHONE.icon} />
            <PhoneNumber>{CONTACT_PHONE.number}</PhoneNumber>
          </PhoneNumberContainer>
        </RightSection>
      </HeaderContent>
    </HeaderBackground>
  );
}
