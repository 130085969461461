import React, { FC } from 'react';
import {
  PopupWrapper,
  PopupCity,
  PopupAddress,
  PopupHoursLabel,
  PopupHours,
  PopupBreaksLabel,
  PopupBreaks,
  PopupContent,
  PopupContainer,
  PopupContentHead,
  PopupContentHours,
  PopupContentBreaks,
  InactiveMessage,
} from './styled';
import { POPUP_HOURS_LABEL, POPUP_BREAKS_LABEL } from './mapConfig';

export interface ICustomPopupProps {
  city: string;
  address: string;
  workingTime: string;
  technicalPauses: string;
  $isMessageActive: boolean;
  activeMessage?: string;
}

const CustomPopup: FC<ICustomPopupProps> = ({
  city,
  address,
  workingTime,
  technicalPauses,
  $isMessageActive,
  activeMessage,
}) => (
  <PopupContainer>
    <PopupWrapper>
      <PopupContent>
        <PopupContentHead>
          <PopupCity>{city}</PopupCity>
          <PopupAddress>{address}</PopupAddress>
        </PopupContentHead>

        <PopupContentHours $isMessageActive={$isMessageActive}>
          <PopupHoursLabel>{POPUP_HOURS_LABEL}</PopupHoursLabel>
          <PopupHours>{workingTime}</PopupHours>
        </PopupContentHours>

        <PopupContentBreaks $isMessageActive={$isMessageActive}>
          <PopupBreaksLabel>{POPUP_BREAKS_LABEL}</PopupBreaksLabel>
          <PopupBreaks>{technicalPauses}</PopupBreaks>
        </PopupContentBreaks>
      </PopupContent>

      {$isMessageActive && activeMessage && <InactiveMessage>{activeMessage}</InactiveMessage>}
    </PopupWrapper>
  </PopupContainer>
);

export default CustomPopup;
