import {
  InstagramIcon,
  TelegramIcon,
  RUFlagIcon,
  GBFlagIcon,
  CallOutlinedIcon,
  ModalIconTelegram,
  ModalIconFlag,
  ModalIconEmail,
  ModalIconFAQ,
  CloseButtonIcon,
} from './assets';

export const socialLinks = [
  {
    icon: InstagramIcon,
    href: 'https://www.instagram.com/betera_by/',
    alt: 'Instagram',
  },
  {
    icon: TelegramIcon,
    href: 'https://t.me/betera_by',
    alt: 'Telegram',
  },
];

export const contactPhone = {
  number: '214',
  href: 'tel:214',
  icon: CallOutlinedIcon,
};

export const languages = [
  {
    code: 'en',
    name: 'English',
    flag: GBFlagIcon,
  },
  {
    code: 'ru',
    name: 'Русский',
    flag: RUFlagIcon,
  },
];

//
// TIP (T.V.): Configuration for modal support window
//

interface SupportItem {
  href: string;
  icon: string;
  heading: string;
  subheading?: string;
}

interface SupportSection {
  label: string;
  items: SupportItem[];
}

interface SupportFooter {
  href: string;
  icon: string;
  tip: string;
}

export const supportData = {
  closeButton: CloseButtonIcon,
  title: 'Служба поддержки',
  sections: [
    {
      label: 'Поддержка в мессенджерах:',
      items: [
        {
          href: 'tg://resolve?domain=BYbeterasupport_bot',
          icon: ModalIconTelegram,
          heading: 'Telegram',
          subheading: '@BYbeterasupport_bot',
        },
      ],
    },
    {
      label: 'Телефон:',
      items: [
        {
          href: 'tel:214',
          icon: ModalIconFlag,
          heading: '214',
          subheading: 'Беларусь: life, А1, MTC',
        },
        {
          href: 'tel:88011009999',
          icon: ModalIconFlag,
          heading: '8-801-100-9999',
          subheading: 'Беларусь',
        },
      ],
    },
    {
      label: 'E-mail:',
      items: [
        {
          href: 'mailto:support@betera.by',
          icon: ModalIconEmail,
          heading: 'support@betera.by',
        },
      ],
    },
  ] as SupportSection[],
  footer: {
    href: 'https://pm.by/ru/pages/faq/',
    icon: ModalIconFAQ,
    tip: 'Найти ответ на вопрос в FAQ',
  } as SupportFooter,
};
