import React, { useState } from 'react';

import contactsData from '../../../data/contacts.json';
import {
  SupportOutlinedIcon,
  IconArrowRight,
  TelegramOutlinedIcon,
  EmailOutlinedIcon,
  CallOutlinedIcon,
} from '../../../assets';
import { noop } from '../../../utils';
import { SupportModal } from './SupportModal';

import {
  ContactsContainer,
  ContactsContent,
  ContactsTitle,
  ContactsList,
  ContactsListItem,
  ItemInner,
  ItemIcon,
  ItemTitle,
  ItemArrow,
} from './styled';
import { IconMappingKeys, ContactsProps } from './types';

const iconMapping = {
  IconSupport: SupportOutlinedIcon,
  IconArrowRight: IconArrowRight,
  IconTelegramSecondary: TelegramOutlinedIcon,
  IconEmail: EmailOutlinedIcon,
  IconCall: CallOutlinedIcon,
};

const DEFAULT_TITLE_SUPPORT = 'Служба поддержки BETERA';

export function Contacts({ highlightContacts }: ContactsProps) {
  const [isSupportModalOpen, setSupportModalOpen] = useState(false);

  const contacts = contactsData.contacts;

  const handleSupportClick =
    (title?: string) => (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (title === DEFAULT_TITLE_SUPPORT) {
        event.preventDefault();

        setSupportModalOpen(true);
      } else {
        noop();
      }
    };

  return (
    <ContactsContainer id="contacts">
      <ContactsContent>
        <ContactsTitle $highlight={highlightContacts}>Контакты</ContactsTitle>

        <ContactsList>
          {contacts.map((contact, index) => (
            <ContactsListItem
              key={index}
              href={contact.href}
              onClick={handleSupportClick(contact?.title)}
            >
              <ItemInner>
                <ItemIcon
                  src={iconMapping[contact.icon as IconMappingKeys]}
                  draggable="false"
                  loading="lazy"
                />
                <ItemTitle>{contact.title}</ItemTitle>
              </ItemInner>
              <ItemArrow src={IconArrowRight} draggable="false" loading="lazy" />
            </ContactsListItem>
          ))}
        </ContactsList>
      </ContactsContent>

      <SupportModal isOpen={isSupportModalOpen} onClose={() => setSupportModalOpen(false)} />
    </ContactsContainer>
  );
}
