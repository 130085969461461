import styled from 'styled-components';

interface INavProps {
  isOpen: boolean;
}

interface IRightSection {
  isOpen: boolean;
}

export const HeaderBackground = styled.header`
  width: 100vw;
  background-color: var(--black);
  position: fixed;
  top: 0;
  z-index: 1001;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 32px 0 20px;
`;

export const Logo = styled.img`
  width: 260px;
  height: auto;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
`;

export const LogoLink = styled.a``;

export const Nav = styled.nav.withConfig({
  shouldForwardProp: (prop: string) => prop !== 'isOpen',
})<INavProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  @media (max-width: 1039px) {
    display: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? 'flex' : 'none')};

    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: var(--bg);
    z-index: 20;
    align-items: start;
    justify-content: flex-start;
    margin-top: 70px;
    padding-top: 10px;
  }
`;

export const NavLink = styled.a`
  color: var(--white);
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  left: 16px;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: var(--link-hover-light);
  }

  @media (max-width: 1039px) {
    font-weight: 500;
    font-size: 24px;
    width: 100vw;

    &:hover::before,
    &:focus::before {
      content: '';
      position: absolute;
      left: -16px;
      top: 0;
      height: 100%;
      width: 3px;
      background-color: var(--control-primary);
    }
  }
`;

export const RightSection = styled.div.withConfig({
  shouldForwardProp: (prop: string) => prop !== 'isOpen',
})<IRightSection>`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 8px;

  @media (max-width: 1039px) {
    display: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? 'flex' : 'none')};
    align-items: center;
    padding: 16px;
    width: 100%;
    background-color: var(--black);
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  gap: 8px;
`;

export const SocialLink = styled.a`
  border-radius: 8px;
  width: 36px;
  height: 36px;
  background-color: var(--bg);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: var(--link-hover-dark);
  }
`;

export const SocialLinkImage = styled.img`
  width: 24px;
  height: 24px;
  user-select: none;
  -webkit-user-select: none;
`;

export const ButtonIcon = styled.img`
  width: 30px;
  height: 20px;
  user-select: none;
  -webkit-user-select: none;
`;

export const BurgerButton = styled.button`
  background: none;
  border: none;
  display: none;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 30px;
  cursor: pointer;
  padding: 0;
  z-index: 21;

  @media (max-width: 1039px) {
    display: flex;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
`;

export const PhoneNumberContainer = styled.a`
  border-radius: 8px;
  height: 36px;
  min-width: 80px;
  background-color: var(--bg);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0 10px;
  cursor: pointer;

  &:hover {
    background-color: var(--link-hover-dark);
  }
`;

export const PhoneNumber = styled.span`
  width: 24px;
  height: auto;
  user-select: none;
  -webkit-user-select: none;
  font-size: 15px;
  font-weight: 500;
  color: var(--white);
`;

export const PhoneNumberIcon = styled.img`
  width: 24px;
  height: auto;
  user-select: none;
  -webkit-user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: auto;
  }
`;
