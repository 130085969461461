import { createSlug } from '../../../utils/createSlug';

export const MAP_ZOOM: number = 12;
export const DEFAULT_MAP_CENTER: [number, number] = [53.90127, 27.560753];

export const CITY_COORDINATES: { [slugifiedCity: string]: [number, number] } = {
  [createSlug('Гродно')]: [53.677841, 23.829515],
  [createSlug('Брест')]: [52.093774, 23.684328],
  [createSlug('Гомель')]: [52.424123, 31.013806],
  [createSlug('Могилев')]: [53.894451, 30.330573],
  [createSlug('Витебск')]: [55.184352, 30.202853],
  [createSlug('Минск')]: [53.90127, 27.560753],
};

export const LAYER_URL_VALUE = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
export const TITLE_LAYER_TEMPLATE =
  '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>';

export const MESSAGE_DEFAULT = 'Для изменения масштаба нажмите Ctrl + прокрутка';
export const MESSAGE_MAC = 'Для изменения масштаба нажмите ⌘ + прокрутка';
export const MESSAGE_WINDOWS = 'Для изменения масштаба нажмите Ctrl + прокрутка';

export const POPUP_HOURS_LABEL = 'Режим работы:';
export const POPUP_BREAKS_LABEL = 'Технические перерывы:';
