import React, { useRef, useEffect, FC } from 'react';

import { useViewMode } from '../../../contexts/ViewModeContext';
import { IShopItem } from '../../../types';
import { SidebarContainer, SelectorContainer, ItemsContainer } from './styled';
import { ShopItem } from './ShopItem/ShopItem';
import { Dropdown } from './Dropdown/Dropdown';

interface IProps {
  onShopItemClick: (latitude: number, longitude: number, shopInfo: IShopItem) => void;
  onMapInteraction: () => void;
  selectedShop: IShopItem | null;
  allShops: IShopItem[];
  handleCityChange: (event: React.FormEvent<HTMLSelectElement>) => void;
  currentCity: string;
  cities: string[];
}

export const Sidebar: FC<IProps> = ({
  onShopItemClick,
  selectedShop,
  allShops,
  handleCityChange,
  currentCity,
  cities,
}) => {
  const { viewMode } = useViewMode();

  const itemRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  useEffect(() => {
    if (selectedShop && itemRefs.current[selectedShop.id]) {
      itemRefs.current[selectedShop.id]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [selectedShop]);

  return (
    <SidebarContainer>
      <SelectorContainer>
        <Dropdown handleCityChange={handleCityChange} currentCity={currentCity} cities={cities} />
      </SelectorContainer>
      {viewMode === 'LIST' && (
        <ItemsContainer>
          {allShops.map((shop) => (
            <ShopItem
              shopInfo={shop}
              key={shop.id}
              onShopItemClick={onShopItemClick}
              isMessageActive={shop.isMessageActive}
              activeMessage={shop.isMessageActive ? shop.activeMessage : undefined}
              isActive={selectedShop?.id === shop.id}
              ref={(el: HTMLDivElement | null) => (itemRefs.current[shop.id] = el)}
            />
          ))}
        </ItemsContainer>
      )}
    </SidebarContainer>
  );
};
