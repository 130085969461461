import React, { useEffect, useState, FC, Dispatch, SetStateAction } from 'react';

import { TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { IShopItem } from '../../../types';
import {
  MESSAGE_DEFAULT,
  MESSAGE_MAC,
  MESSAGE_WINDOWS,
  LAYER_URL_VALUE,
  TITLE_LAYER_TEMPLATE,
} from './mapConfig';
import { MapInner, MapWrapper, OverlayContainer, OverlayPopup, OverlayMessage } from './styled';
import CustomMarker from './CustomMarker';

interface IMapProps {
  center: [number, number];
  zoom: number;
  allShops: IShopItem[];
  selectedShop: IShopItem | null;
  isInteractionBlocked: boolean;
  onShopItemClick: (latitude: number, longitude: number, shopInfo: IShopItem) => void;
}

const MapEventsHandler: FC<{
  setShowMessage: Dispatch<SetStateAction<boolean>>;
  isInteractionBlocked: boolean;
}> = ({ setShowMessage, isInteractionBlocked }) => {
  const map = useMap();

  useEffect(() => {
    if (isInteractionBlocked) {
      map.dragging.disable();
      map.touchZoom.disable();
      map.scrollWheelZoom.disable();
      return;
    }
    const userAgent = window.navigator.userAgent;
    const isMobileDevice = /iPhone|iPad|Android/i.test(userAgent);

    if (isMobileDevice) {
      map.dragging.disable();
      map.touchZoom.disable();

      const handleTouchMove = (event: TouchEvent) => {
        if (event.touches.length === 2) {
          map.dragging.enable();
          map.touchZoom.enable();
        } else {
          map.dragging.disable();
          map.touchZoom.disable();
        }
      };

      map.getContainer().addEventListener('touchmove', handleTouchMove, { passive: false });

      setShowMessage(false);

      return () => {
        map.getContainer().removeEventListener('touchmove', handleTouchMove);
      };
    } else {
      map.scrollWheelZoom.disable();

      const handleWheel = (event: WheelEvent) => {
        if (event.ctrlKey) {
          event.preventDefault();
          map.scrollWheelZoom.enable();
          setShowMessage(false);
        } else {
          map.scrollWheelZoom.disable();
          setShowMessage(true);
        }
      };

      map.getContainer().addEventListener('wheel', handleWheel, { passive: false });

      return () => {
        map.getContainer().removeEventListener('wheel', handleWheel);
      };
    }
  }, [map, setShowMessage, isInteractionBlocked]);

  return null;
};

export const Map: FC<IMapProps> = ({
  center,
  zoom,
  allShops,
  selectedShop,
  isInteractionBlocked,
  onShopItemClick,
}) => {
  const [showMessage, setShowMessage] = useState(true);
  const [messageText, setMessageText] = useState(MESSAGE_DEFAULT);
  const [highlightedShop, setHighlightedShop] = useState<IShopItem | null>(null);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;

    if (/Mac/i.test(userAgent)) {
      setMessageText(MESSAGE_MAC);
    } else if (/Windows/i.test(userAgent)) {
      setMessageText(MESSAGE_WINDOWS);
    }
  }, []);

  useEffect(() => {
    if (selectedShop) {
      setHighlightedShop(selectedShop);
    } else {
      setHighlightedShop(null);
    }
  }, [selectedShop]);

  return (
    <MapWrapper>
      <MapInner center={center} zoom={zoom}>
        {showMessage && (
          <OverlayContainer>
            <OverlayPopup>
              <OverlayMessage>{messageText}</OverlayMessage>
            </OverlayPopup>
          </OverlayContainer>
        )}

        <TileLayer url={LAYER_URL_VALUE} attribution={TITLE_LAYER_TEMPLATE} />

        {allShops.map((shop) => (
          <CustomMarker
            key={shop.id}
            position={[shop.latitude || 0, shop.longitude || 0]}
            shopInfo={shop}
            isHighlighted={highlightedShop?.id === shop.id}
            onShopItemClick={onShopItemClick}
          />
        ))}

        <MapEventsHandler
          setShowMessage={setShowMessage}
          isInteractionBlocked={isInteractionBlocked}
        />
      </MapInner>
    </MapWrapper>
  );
};
