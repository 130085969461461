import React, { useEffect, useRef, FC } from 'react';
import L from 'leaflet';
import { Marker, useMap } from 'react-leaflet';

import { MapPointIcon } from '../../../assets/icons';
import { IShopItem } from '../../../types';
import CustomPopup from './CustomPopup';

interface ICustomMarkerProps {
  position: [number, number];
  shopInfo: IShopItem;
  isHighlighted: boolean;
  onShopItemClick: (latitude: number, longitude: number, shopInfo: IShopItem) => void;
}

const CustomMarker: FC<ICustomMarkerProps> = ({
  position,
  shopInfo,
  isHighlighted,
  onShopItemClick,
}) => {
  const map = useMap();
  const markerRef = useRef<L.Marker>(null);

  useEffect(() => {
    if (isHighlighted && markerRef.current) {
      const newZoom = map.getZoom() < 17 ? 17 : map.getZoom();

      map.flyTo(position, newZoom, {
        animate: true,
        duration: 1,
      });

      markerRef.current.getElement()?.classList.add('highlight-marker');

      const timeout = setTimeout(() => {
        if (markerRef.current) {
          markerRef.current.getElement()?.classList.remove('highlight-marker');
        }
      }, 2000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isHighlighted, position, map]);

  const customIcon = L.icon({
    iconUrl: MapPointIcon,
    iconSize: [38, 38],
    iconAnchor: [22, 38],
  });

  return (
    <Marker
      position={position}
      icon={customIcon}
      ref={markerRef}
      eventHandlers={{
        click: () => {
          onShopItemClick(position[0], position[1], shopInfo);
        },
      }}
    >
      {shopInfo && (
        <CustomPopup
          city={shopInfo.city}
          address={shopInfo.address}
          workingTime={shopInfo.workingTime}
          technicalPauses={shopInfo.technicalPauses}
          $isMessageActive={shopInfo.isMessageActive}
          activeMessage={shopInfo.isMessageActive ? shopInfo.activeMessage : undefined}
        />
      )}
    </Marker>
  );
};

export default CustomMarker;
